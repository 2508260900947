<template>
  <div class="d-flex flex-wrap">
    <!-- <stripe-checkout
      class="stripe"
      ref="checkoutRef"
      mode="subscription"
      :pk="pk"
      :success-url="successURL"
      :cancel-url="cancelURL"
      v-if="pk"
    /> -->
    <div
      class="col-md-4 stretch-card grid-margin"
      v-for="item in items"
      :key="item.id"
    >
      <div class="card bg-success card-img-holder">
        <div class="card-body" @click="submit(item.id)">
          <img
            src="@/assets/images/dashboard/circle.svg"
            class="card-img-absolute"
            alt="circle-image"
          />
          <h4 class="font-weight-normal mb-3 text-white">
            {{ item.name }}
            <i class="icon-diamond mdi-24px float-right"></i>
          </h4>
          <h2 class="mb-5 text-white">{{ item.credits }} credits</h2>
          <h6 class="card-text text-white">${{ item.price }}</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { StripeCheckout } from "@vue-stripe/vue-stripe";
import stripe from 'stripe'
export default {
  data() {
    return {
      items: null,
      stripe: null,
      successURL: null,
      cancelURL: null,
    };
  },
  // components: {
  //   StripeCheckout,
  // },
  created() {
    this.pk = process.env.VUE_APP_PK;
    this.successURL = window.location.URL;
    this.cancelURL = window.location.URL;
  },
  mounted() {
    // const data = {
    //   name: "Gold",
    // };
    if(this.$route.query.payment) {
      this.$swal.fire("Payment error!", "", "error");
    }
    this.stripe = window.Stripe(process.env.VUE_APP_PK);

    this.loadItems();
    // this.$axios("https://api.stripe.com/v1/products", {
    //   method: "POST",
    //   data,
    //   headers: {
    //     Authorization: `Bearer ${process.env.VUE_APP_SK_TEST}`,
    //     "Content-Type": "application/x-www-form-urlencoded",
    //   },
    // })
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  },
  methods: {
    loadItems() {
      this.$store.commit("updateLoading", true);

      this.$axios("/plans/list")
        .then((res) => {
          this.items = res.data.plans;
          this.$store.commit("updateLoading", false);
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("updateLoading", false);
        });
    },
    submit(id) {
      console.log(this.stripe)
      this.$axios.post(`/credits/payment_session?plan_id=${id.toString()}`).then(res => {
        console.log(res)
        this.stripe.redirectToCheckout({ sessionId: res.data.id });
      }).catch(err => {
        console.log(err)
      })
  
    },
  },
};
</script>
<style scoped>
.card .card-body {
  padding: 1rem 1.875rem;
  cursor: pointer;
  position: relative;
}
h6 {
  font-size: 21px;
}
</style>
